import { Swiper, Autoplay, Navigation } from 'swiper';

export default function () {
  Swiper.use([Autoplay, Navigation]);

  const defaultConfig = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 30,
    preloadImages: true,
    updateOnImagesReady: true,
    loop: true,
  };

  // Configuration overrides
  const configurations = new Map();

  configurations.set('product-gallery-thumbs', {
    init: false,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 15,
    freeMode: true,
    watchSlidesProgress: true,
    centeredSlides: true,
    navigation: {
      nextEl: '.js-slider-next',
      prevEl: '.js-slider-prev',
    },
  });

  // Init sliders
  const sliders = [...document.querySelectorAll('.js-slider')];
  sliders.forEach((sliderEl) => {
    let config = configurations.get(sliderEl.dataset.config);
    if (!config) {
      config = defaultConfig;
    }

    window.slider = new Swiper(sliderEl.querySelector('.swiper'), config);
    window.slider.init();
  });
}
