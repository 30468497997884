/* eslint-disable no-useless-concat */
/* eslint-disable no-plusplus */
import $ from 'jquery';
import StoreLocator from '../components/store-locator/StoreLocator';
import Helpers from '../utils/Helpers';

export default class InspectorsIndex {
  constructor() {
    this.elementsToFilter = document.querySelectorAll('.grid__item[data-category]');
    this.categorySelect = document.getElementById('category-select');
    this.waterFilter = document.getElementById('water-filter');
    this.waterFilterA = document.getElementById('water-filter-a');
    this.waterFilterB = document.getElementById('water-filter-b');
    this.waterFilterC = document.getElementById('water-filter-c');
    this.sewerFilter = document.getElementById('sewer-filter');
    this.resultsFilterList = document.getElementById('locations-list');
    this.resultItems = null;
    this.noResultsItem = null;

    if (this.resultsFilterList) {
      this.resultItems = this.resultsFilterList.getElementsByTagName('li');
      this.noResultsItem = this.resultsFilterList.querySelector('.js-locations-list-empty');
    }

    this.storeLocator = new StoreLocator();
    this.autoCompleteService = new google.maps.places.AutocompleteService(); // eslint-disable-line
    this.init();
  }

  init() {
    this.storeLocator.init();
    this.storeLocator.addListener('list-available', () => {
      const query = Helpers.getQueryParams(document.location.search);
      if (query.search) {
        $('#location-search-field').val(query.search);
        $('#locations-search').trigger('submit');
      }

      this.updateMarkers();
    });

    this.waterFilter.addEventListener('change', () => {
      this.waterFilterC.checked = this.waterFilter.checked;

      if (!this.waterFilter.checked) {
        this.waterFilterA.checked = this.waterFilter.checked;
        this.waterFilterB.checked = this.waterFilter.checked;
      }

      this.updateMarkers();
    });

    this.waterFilterA.addEventListener('change', () => {
      this.updateMarkers();
    });

    this.waterFilterB.addEventListener('change', () => {
      this.updateMarkers();
    });

    this.waterFilterC.addEventListener('change', () => {
      this.updateMarkers();
    });

    this.sewerFilter.addEventListener('change', () => {
      this.updateMarkers();
    });

    const $searchInput = $('.ui.search input');
    window.updateQuery = (query) => {
      $('#location-search-field').val(query);
      $('#locations-search').trigger('submit');
    };
    $('.ui.search.sewer-search')
      .search({
        templates: {
          standard: (response) => { // eslint-disable-line
            let htmlResponse = '';
            response.results.forEach((result) => {
              htmlResponse = `${htmlResponse}
                <a class="result" onclick="window.updateQuery('${result.description}');">
                  <div class="content">
                    <div class="description">${result.description}</div>
                  </div>
                </a>
              `;
            });
            return htmlResponse;
          },
        },
        apiSettings: {
          onResponse: (apiResponse) => {
            const response = {
              results: apiResponse.results,
            };
            return response;
          },
          responseAsync: (settings, callback) => {
            const request = { input: $searchInput.val() };
            this.autoCompleteService.getQueryPredictions(request, (results, status) => {
              let response = {
                success: false,
              };
              if (status === google.maps.places.PlacesServiceStatus.OK) { // eslint-disable-line
                response = {
                  success: true,
                  results,
                };
              }
              callback(response);
            });
          },
        },
      });
  }

  checkResults() {
    // Iterate over the items and check if any of them don't have the class "hide"
    let hasVisibleItems = false;
    for (let i = 0; i < this.resultItems.length; i++) {
      if (!this.resultItems[i].classList.contains('hide') && !this.resultItems[i].classList.contains('js-locations-list-empty') && !this.resultItems[i].classList.contains('loading-indicator')) {
        hasVisibleItems = true;
        break;
      }
    }

    // Check the result
    if (hasVisibleItems) {
      this.noResultsItem.classList.add('hide');
    } else {
      this.noResultsItem.classList.remove('hide');
    }
  }

  updateMarkers() {
    this.waterFilter.checked = this.waterFilterA.checked || this.waterFilterB.checked
    || this.waterFilterC.checked;

    this.storeLocator.googleMarkers.forEach((item) => {
      const { googleMarker, typeClasses, id } = item;
      const ofFilterConditions = [
        { type: 'type-water-klasse-a', checkbox: this.waterFilterA },
        { type: 'type-water-klasse-b', checkbox: this.waterFilterB },
        { type: 'type-water-klasse-c', checkbox: this.waterFilterC },
      ];

      const isOFFilterVisible = ofFilterConditions.some((cond) => {
        const { type, checkbox } = cond;
        return typeClasses.includes(type) && checkbox.checked;
      });

      const isWaterTypeVisible = ((this.waterFilterA.checked && typeClasses.indexOf('type-water-klasse-a') >= 0)
                                || (this.waterFilterB.checked && typeClasses.indexOf('type-water-klasse-b') >= 0)
                                || (this.waterFilterC.checked && typeClasses.indexOf('type-water-klasse-c') >= 0));
      const isSewerTypeVisible = this.sewerFilter.checked && typeClasses.indexOf('type-riool') >= 0;

      if ((isWaterTypeVisible && isSewerTypeVisible)
      || (!this.waterFilter.checked && isSewerTypeVisible)) {
        googleMarker.setVisible(true);
        $(`#${id}`).removeClass('hide');
      } else if (isWaterTypeVisible && !this.sewerFilter.checked) {
        if (isOFFilterVisible) {
          googleMarker.setVisible(true);
          $(`#${id}`).removeClass('hide');
        } else {
          googleMarker.setVisible(false);
          $(`#${id}`).addClass('hide');
        }
      } else {
        googleMarker.setVisible(false);
        $(`#${id}`).addClass('hide');
      }
    });

    this.checkResults();
  }
}
