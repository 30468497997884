import $ from 'jquery';
import { matchMobile } from '../../constants/defaults';

export default function () {
  const $nav = $('.main-nav__wrap');
  const $navTrigger = $('.main-nav__trigger');

  $navTrigger.on('click', (e) => {
    e.preventDefault();
    const $mainNav = $(e.currentTarget).next('.main-nav__wrap');
    $mainNav.toggle();
    if ($mainNav.is(':visible')) {
      $('html').addClass('has-popup-visible');
      $navTrigger.addClass('active');
    } else {
      $('html').removeClass('has-popup-visible');
      $navTrigger.removeClass('active');
    }
  });

  matchMobile.addListener((mediaQuery) => {
    if (mediaQuery.matches) {
      $nav.css('display', 'block');
    } else {
      $nav.css('display', 'none');
    }
  });
}
