import $ from 'jquery';
import '../../plugins/semantic-transition';
import '../../plugins/semantic-dropdown';

export default function () {
  const $dropdowns = $('.ui.dropdown');
  if ($dropdowns.length) {
    $dropdowns.dropdown();
  }
}
