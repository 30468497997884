/* eslint-disable no-plusplus */
import $ from 'jquery';

import googleMapsStyles from '../../constants/googleMapsStyles';
import Infobox from './infobox';

const defaultMapData = {
  holderId: 'store-locator-map',
  lat: 51.0325538,
  long: 3.7333816,
};

export default class StoreLocator {
  init(mapSettings = null) {
    const google = window.google || {};
    this.googleMaps = google.maps;
    this.baseUrl = window.baseUrl || '';
    this.listeners = new Map();
    this.googleMarkers = [];
    this.pureList = [];

    // override default map data if param is set
    const mapData = mapSettings || defaultMapData;
    const holder = document.getElementById(mapData.holderId);

    if (holder) {
      this.map = this.addMap(holder, mapData.lat, mapData.long);

      this.getLocations();
      this.search();
    }
  }

  getLocations() {
    $.getJSON(`${window.SITE_URL}search/inspectors`, (data) => {
      const dataArr = data.data;
      this.pureList = dataArr;
      const shuffledArr = this.shuffle(dataArr);

      this.markers = shuffledArr;

      for (let i = 0; i < this.markers.length; i += 1) {
        const marker = this.markers[i];
        marker.id = `${marker.title}-${marker.longitude}-${marker.latitude}`.replace(/[^a-zA-Z0-9-]/g, '');
        const { googleMarker, typeClasses } = this.addMarker(marker, false);
        this.googleMarkers.push({ googleMarker, typeClasses, id: marker.id });
      }

      const $list = $('#locations-list');
      $list.removeClass('loading');
      this.emit('list-available');
    });
  }

  addListener(label, callback) {
    if (!this.listeners.has(label)) {
      this.listeners.set(label, []);
    }
    this.listeners.get(label).push(callback);
  }

  emit(label, ...args) {
    const listeners = this.listeners.get(label);
    if (listeners && listeners.length) {
      listeners.forEach((listenerCallback) => {
        listenerCallback(...args);
      });
      return true;
    }
    return false;
  }

  shuffle(array) {
    return array.map(a => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map(a => a.value);
  }

  splitList(list) {
    const $list = list;
    const originalArray = $list.find('[data-distance]:not(.hide)').toArray();

    const splitArrays = [];
    let currentDataset = null;
    let currentSplit = null;

    originalArray.forEach((item) => {
      const { dataset } = item;
      const itemLower = currentDataset !== null
        ? parseInt(dataset.distance, 10) < parseInt(currentDataset, 10) : false;

      if (itemLower) {
        const rightArray = splitArrays.find(r => r.some(
          obj => obj.dataset.distance === dataset.distance,
        ));
        if (rightArray && rightArray.length > 0) {
          rightArray.push(item);

          // randomize new array
          const rightArrIndex = splitArrays.indexOf(rightArray);
          const shuffledRightArr = this.shuffle(rightArray);
          splitArrays[rightArrIndex] = shuffledRightArr;
        }
      }

      // If the dataset changes, create a new split array
      if (dataset.distance !== currentDataset && !itemLower) {
        if (currentSplit !== null) {
          // randomize split
          const shuffledCurrSplit = this.shuffle(currentSplit);
          splitArrays.push(shuffledCurrSplit);
        }

        currentSplit = [];
        currentDataset = dataset.distance;
      }

      // Add the item to the current split
      if (!itemLower) {
        currentSplit.push(item);
      }
    });

    // Add the last split array to the result
    if (currentSplit !== null) {
      // randomize split
      const shuffledCurrSplit = this.shuffle(currentSplit);
      splitArrays.push(shuffledCurrSplit);
    }

    // Combine all split arrays into one using the spread operator
    const combinedArray = [].concat(...splitArrays);
    $list.empty().append(combinedArray);
  }

  search() {
    const $list = $('#locations-list');

    $('#locations-search').submit((event) => {
      event.preventDefault();

      $list.addClass('loading');

      const search = `${$('#location-search-field').val()}, Belgium`;
      const geocoder = new this.googleMaps.Geocoder();

      geocoder.geocode({ address: search },
        (data, status) => {
          $list.removeClass('loading');

          if (status === this.googleMaps.GeocoderStatus.OK) {
            const latitude = data[0].geometry.location.lat();
            const longitude = data[0].geometry.location.lng();
            const markerData = {
              id: `${longitude}-${latitude}`,
              address: {
                latitude,
                longitude,
              },
            };

            // add marker and center map
            this.addMarker(markerData, true);

            if (this.markers) {
              // reorder list
              for (let i = 0; i < this.markers.length; i += 1) {
                const marker = this.markers[i];
                const distance = this.calculateDistance(
                  latitude,
                  longitude,
                  marker.latitude,
                  marker.longitude,
                );

                const $marker = $(`#${marker.id}`);
                $marker.attr('data-distance', distance);
                const distanceString = `${Math.round(distance / 100) / 10}`.replace('.', ',');
                $marker.find('.location-list__distance').html(distanceString);
              }

              $list.find('li').sort((a, b) => {
                const distanceA = $(a).attr('data-distance');
                const distanceB = $(b).attr('data-distance');
                return parseInt(distanceA, 10) - parseInt(distanceB, 10);
              }).appendTo($list);

              this.splitList($list);
            } else {
              // Error handling
            }
          }
        });
    });
  }

  addMap(holder, latitude, longitude) {
    const zoom = 9;
    const disable = true;
    const scroll = false;
    const styledMap = new this.googleMaps.StyledMapType(
      googleMapsStyles,
      { name: 'Styled Map' },
    );
    const mapCenter = new this.googleMaps.LatLng(latitude, longitude);
    const mapOptions = {
      zoom,
      panControl: true,
      zoomControl: disable,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      minZoom: 2,
      scrollwheel: scroll,
      center: mapCenter,
      mapTypeId: this.googleMaps.MapTypeId.ROADMAP,
    };
    const map = new this.googleMaps.Map(holder, mapOptions);

    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');

    // this.googleMaps.event.addDomListener(window, 'resize', () => {
    //   map.setCenter(mapCenter);
    // });

    this.infobox = new Infobox({
      content: '',
      disableAutoPan: false,
      maxWidth: 180,
      pixelOffset: new this.googleMaps.Size(-175, -220),
      zIndex: null,
      boxStyle: {
        width: '350px',
        height: '200px',
      },
      closeBoxMargin: '0 0 2px 4px',
      // closeBoxURL: '../assets/images/close.png',
      infoBoxClearance: new this.googleMaps.Size(1, 1),
    });

    return map;
  }

  addMarker(data, isHome) {
    const {
      id,
    } = data;

    const name = (data.title) ? data.title : '';
    const approvalNumber = (data.approvalNumber) ? data.approvalNumber : '';
    const address = (data.address && typeof data.address === 'string') ? data.address.split(',')[0] : '';
    const addressLine2 = (data.address && typeof data.address === 'string') ? data.address.split(',')[1] : '';

    let phoneRaw = '';
    let phoneNumber = '';
    if (data.phone) {
      phoneRaw = (data.phone) ? data.phone : '';
      phoneNumber = data.phone.replace(/\s/g, ''); // eslint-disable-line
    }
    const email = (data.email) ? data.email : '';
    let types = '';
    let typeClasses = '';

    if (data.type) {
      for (let i = 0; i < data.type.length; i += 1) {
        const labelTypes = data.type[i].label.includes('klasse') ? data.type[i].label.replace('Water', 'W.') : data.type[i].label;
        types = `${types} ${types.length > 0 ? '<br>' : ''} ${labelTypes}`;
        //
        const label = data.type[i].label.toLowerCase().replace(/\s/g, '-');
        typeClasses = `${typeClasses} type-${label}`;
      }
    }

    const contact = (data.email) ? data.email : '';
    const lat = parseFloat(data.latitude);
    const long = parseFloat(data.longitude);

    const myLatlng = new this.googleMaps.LatLng(lat, long);

    const markerImg = (isHome) ? window.markerImgHome : window.markerImg;

    const markerIcon = {
      url: markerImg,
      size: new this.googleMaps.Size(68, 68),
      origin: new this.googleMaps.Point(0, 0),
      anchor: new this.googleMaps.Point(17, 17),
      scaledSize: new this.googleMaps.Size(34, 34),
    };

    const marker = new this.googleMaps.Marker({
      position: myLatlng,
      map: this.map,
      icon: markerIcon,
    });

    const query = encodeURI(`${name}+${address}`);
    const routeUrl = `https://www.google.com/maps/search/?api=1&query=${query}`;

    const contentList = `
      <div class="location-list__distance" data-label="KM"></div>
      <div class="location-list__contact--tel" data-label="Erkenningsnr.">${approvalNumber}</div>
      <div class="location-list__title" data-label="Naam keurder">${name}</div>
      <div class="location-list__contact" data-label="E-mailadres"><a href="mailto:${email}">${email}</a></div>
      <div class="location-list__contact location-list__contact--tel" data-label="Telefoon keurder"><a href="tel:${phoneNumber}">${phoneRaw}</a></div>
      <div class="location-list__address" data-label="Adres">
        ${address}<br/>
        ${addressLine2}
      </div>
      <div class="location-list__types" data-label="Type">
        ${types}
      </div>
    `;
    const contentBox = `
      <div class="infoBox__title">${name}</div>
      <p>
        ${address.replace(' - ', '<br/>')}<br/>
        ${addressLine2}<br/>
        ${contact}<br/>
      </p>
      <p><a href="${routeUrl}" target="_blank">Routebeschrijving</a></p>
    `;
    // add list item
    if (!isHome) {
      $('<li/>')
        .attr('id', id)
        .addClass(typeClasses)
        .html(contentList)
        .appendTo('#locations-list')
        .click(() => {
          this.showInfobox(marker, contentBox);
        })
        .hover(() => {
          if (marker.getAnimation() != null) {
            marker.setAnimation(null);
          } else {
            marker.setAnimation(this.googleMaps.Animation.BOUNCE);
          }
        });

      this.googleMaps.event.addListener(marker, 'click', () => { // eslint-disable-line
        this.showInfobox(marker, contentBox);
        return false;
      });
    }

    if (isHome) {
      const centerLat = lat;
      const centerLong = long - 0.007;
      const centerPos = new this.googleMaps.LatLng(centerLat, centerLong);

      this.map.setCenter(centerPos);
      this.map.setZoom(14);

      if (this.homeMarker) {
        this.homeMarker.setMap(null);
      }

      this.homeMarker = marker;
    }

    // this.randomizeList();

    return { googleMarker: marker, typeClasses };
  }

  showInfobox(marker, content) {
    this.infobox.close();
    this.infobox.setContent(content);
    this.infobox.open(this.map, marker);
  }

  calculateDistance(lat1, lon1, lat2, lon2) {
    const toRadians = degrees => degrees * Math.PI / 180;

    const φ1 = toRadians(lat1);
    const φ2 = toRadians(lat2);
    const Δλ = toRadians(lon2 - lon1);

    const Δφ = φ2 - φ1;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2)
            + Math.cos(φ1) * Math.cos(φ2)
            * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distanceInMeters = 6371000 * c; // Earth's radius in meters

    return Math.round(distanceInMeters);
  }
}
