import PageSearch from '../filters/PageSearch';

export default class MaterialInstructions {
  constructor() {
    this.init();
  }

  init() {
    const searchInput = document.getElementById('search');
    const elementGroups = [];
    [...document.querySelectorAll('.stacked-table')].forEach((table) => {
      elementGroups.push(
        {
          elements: table.querySelectorAll('.stacked-table__row'),
          noResultsEl: table.querySelector('.no-results'),
          noResultsText: 'Het materiaalvoorschrift dat u zoekt kon niet in deze lijst worden gevonden',
        },
      );
    });
    const pageSearch = new PageSearch(searchInput, elementGroups); // eslint-disable-line
  }
}
