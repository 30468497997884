export default function () {
  [...document.querySelectorAll('.trajectory')].forEach((trajectory) => {
    const items = [...trajectory.querySelectorAll('.trajectory__item')];
    items.forEach((el) => {
      el.addEventListener('click', () => {
        trajectory.querySelector('.trajectory__item--active').classList.remove('trajectory__item--active');
        el.classList.add('trajectory__item--active');
      });
    });
  });
}
