import { ModalManager } from '@esign/modal';
// import Cookies from 'js-cookie';

import videojs from 'video.js/dist/video';
import objectFitImages from 'object-fit-images';
import navigation from './components/layout/navigation';
import swiper from './components/layout/swiper';
import responsiveVideos from './components/layout/responsiveVideos';
import validation from './components/forms/validation';
import dropzone from './components/forms/dropzone';
import RecaptchaForm from './components/forms/RecaptchaForm';
import faqBlocks from './components/faq-block/init';
import visibilityTriggers from './components/visibility-trigger/init';
import semanticDropdowns from './components/semantic-dropdowns/init';
import trajectories from './components/trajectory/init';
import scrollLinks from './components/scroll-link/init';
import enlargeBlocks from './components/enlarge-block/init';
import gdpr from './components/forms/gdpr';
import freeformTable from './components/forms/freeformTable';
// import contest from './components/popup/contest';

import Home from './pages/Home';
import Contact from './pages/Contact';
import CompanySearch from './pages/CompanySearch';
import MyWater from './pages/MyWater';
import MaterialInstructions from './pages/MaterialInstructions';
import InspectionEducationIndex from './pages/InspectionEducationIndex';
import PublicationsCategoriesIndex from './pages/PublicationsCategoriesIndex';
import InspectorsIndex from './pages/InspectorsIndex';
import InspectionsIndex from './pages/InspectionsIndex';

import Waardanwel from './pages/waardanwel/Home';

import asyncVideo from './utils/asyncVideo';

import CookieNotification from './components/cookies/CookieNotification';


require('./utils/nativeConsole');
// require('jquery-touchswipe/jquery.touchSwipe'); // use with fancybox, cycle2, etc
window.modalManager = new ModalManager();

// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

// Layout setup
navigation();
swiper();
responsiveVideos();
asyncVideo();
window.cookieNotification = new CookieNotification();

// Forms
validation();
dropzone();
gdpr();
freeformTable();

// Components
objectFitImages();
faqBlocks();
visibilityTriggers();
trajectories();
semanticDropdowns();
scrollLinks();
enlargeBlocks();
const videoElements = document.querySelector('.video-js');
if (videoElements) {
  videojs(document.querySelector('.video-js'));
}

const newsletterForm = new RecaptchaForm('#form-newsletter');
window.submitRecaptchaFormNewsletter = () => {
  newsletterForm.submitCallback();
};

// Enable this if you want to test ga calls in development
// gaDevelopment();

// Page specific classes
const pages = {
  Contact,
  CompanySearch,
  MyWater,
  MaterialInstructions,
  InspectionEducationIndex,
  Home,
  PublicationsCategoriesIndex,
  InspectorsIndex,
  InspectionsIndex,
  Waardanwel,
};

const currentPage = document.documentElement.getAttribute('data-page');
if (currentPage) {
  const pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    // Exceptional use of new
    // eslint-disable-next-line no-new
    new pages[pageClassName]();
  }
}

window.modalManager.checkModalOnPageLoad();

// contest popup
// if (!Cookies.get('popup-contest')) {
//   const modal = window.modalManager.idModalMap.get('contest-modal');
//   if (modal) {
//     modal.show();
//   }

//   Cookies.set('popup-contest', true, { expires: 365 });
// }
