import $ from 'jquery';
import 'jquery-ui/ui/effect';
import '../plugins/semantic-search';

export default class InspectionsIndex {
  constructor() {
    this.autoCompleteService = new google.maps.places.AutocompleteService(); // eslint-disable-line
    this.init();
  }

  init() {
    this.initSewerSearch();
  }

  initSewerSearch() {
    window.goToSewerPageWithQuery = (description) => {
      window.location.href = `${window.SITE_URL}keurders?search=${description}`;
    };
    const $searchInput = $('.ui.search.sewer-search input');
    $('.ui.search.sewer-search')
      .search({
        templates: {
          standard: (response) => { // eslint-disable-line
            let htmlResponse = '';
            response.results.forEach((result) => {
              htmlResponse = `${htmlResponse}
                <a class="result" onclick="window.goToSewerPageWithQuery('${result.description}');">
                  <div class="content">
                    <div class="description">${result.description}</div>
                  </div>
                </a>
              `;
            });
            return htmlResponse;
          },
        },
        apiSettings: {
          onResponse: (apiResponse) => {
            const response = {
              results: apiResponse.results,
            };
            return response;
          },
          responseAsync: (settings, callback) => {
            const request = { input: $searchInput.val() };
            this.autoCompleteService.getQueryPredictions(request, (results, status) => {
              let response = {
                success: false,
              };
              if (status === google.maps.places.PlacesServiceStatus.OK) { // eslint-disable-line
                response = {
                  success: true,
                  results,
                };
              }
              callback(response);
            });
          },
        },
      });
  }
}
