export default function () {
  const emergencyForm = document.querySelector('.emergency-form');

  if (emergencyForm) {
    const headings = emergencyForm.querySelectorAll('th');

    [...headings].forEach((heading) => {
      const text = heading.innerHTML;
      const outerWrapper = document.createElement('div');
      const innerWrapper = document.createElement('div');
      heading.innerHTML = ''; // eslint-disable-line no-param-reassign
      innerWrapper.innerHTML = text; // eslint-disable-line no-param-reassign
      outerWrapper.appendChild(innerWrapper);
      heading.appendChild(outerWrapper);
    });
  }

  const formRows = [...document.querySelectorAll('.form-row')];
  const formAddRowTrigger = document.getElementById('form-add-row');

  if (formRows.length && formAddRowTrigger) {
    let rowsVisible = 1;
    formRows[0].style.display = 'block';

    formAddRowTrigger.addEventListener('click', () => {
      if (rowsVisible < 10) {
        if (formRows[rowsVisible]) {
          formRows[rowsVisible].style.display = 'block';
          rowsVisible += 1;
        }
      }

      if (rowsVisible === 10) {
        formAddRowTrigger.style.display = 'none';
      }
    });
  }
}
