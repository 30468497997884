/* eslint-disable */
import $ from 'jquery';

export default function() {
  $("a.scroll-link").click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
      && location.hostname == this.hostname) {
      var $target = $(this.hash);
      if(this.hash.slice(1)) {
        $target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
        if ($target.length) {
          var padding = 0;
          $('html, body').animate({
            scrollTop: $target.offset().top - padding
          }, 300);
          return false;
        }
      } else {
        $('html, body').animate({
          scrollTop: 0
        }, 300);
      }
    }
  });
}
