import $ from 'jquery';

export default function () {
  let $checkedArray = 0;
  const $gdpr = $('.js-gdpr');

  // eslint-disable-next-line func-names
  $gdpr.each(function () {
    const $input = $(this);
    const $form = $input.closest('form');
    const $submitInput = $form.find('button[type="submit"], input[type="submit"]');

    const isChecked = () => {
      if ($checkedArray === $gdpr.length) {
        $submitInput.removeAttr('disabled');
      } else {
        $submitInput.attr('disabled', 'disabled');
      }
    };

    isChecked();

    $input.change(() => {
      if ($input.is(':checked')) {
        $checkedArray += 1;
      } else {
        $checkedArray -= 1;
      }

      isChecked();
    });
  });
}
