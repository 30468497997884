import $ from 'jquery';
import 'jquery-ui/ui/effect';
import '../plugins/semantic-api';
import '../plugins/semantic-search';

export default class PublicationsCategoriesIndex {
  constructor() {
    this.init();
    this.companiesJSON = null;
  }

  init() {
    $('.ui.search')
      .search({
        apiSettings: {
          url: 'search/publications?search={query}',
        },
        fields: {
          results: 'data',
        },
        error: {
          source: 'Cannot search. No source used, and Semantic API module was not included',
          noResults: 'Er werden geen resultaten gevonden',
          logging: 'Error in debug logging, exiting.',
          noTemplate: 'A valid template name was not specified.',
          serverError: 'There was an issue with querying the server.',
          maxResults: 'Results must be an array to use maxResults setting',
          method: 'The method you called is not defined.',
        },
      });
  }
}
