import $ from 'jquery';
import 'jquery-ui/ui/effect';
import '../plugins/semantic-search';
import '@chenfengyuan/datepicker/dist/datepicker';

export default class Home {
  constructor() {
    this.companiesJSON = null;
    this.eventsLoaded = false;
    this.inspectionEucationLoaded = false;
    this.events = null;
    this.inspectionEducation = null;
    this.init();
    this.initSewerSearch();
    this.autoCompleteService = new google.maps.places.AutocompleteService(); // eslint-disable-line
  }

  init() {
    $.getJSON(`${window.SITE_URL}cities.json`, this.onDataReceived);
    $.getJSON(`${window.SITE_URL}events.json`, data => this.onEventsDataReceived(data));
    $.getJSON(`${window.SITE_URL}inspection-education.json`, data => this.onEducationInspectionsDataReceived(data));
  }

  onDataReceived(data) {
    this.companiesJSON = data.data;
    const content = [];
    this.companiesJSON.forEach((company) => {
      content.push({ title: company.title, description: company.zip, url: company.url });
    });

    $('.ui.search.water-search')
      .search({
        source: content,
        error: {
          source: 'Cannot search. No source used, and Semantic API module was not included',
          noResults: 'Er werden geen resultaten gevonden',
          logging: 'Error in debug logging, exiting.',
          noTemplate: 'A valid template name was not specified.',
          serverError: 'There was an issue with querying the server.',
          maxResults: 'Results must be an array to use maxResults setting',
          method: 'The method you called is not defined.',
        },
      });
  }

  initSewerSearch() {
    window.goToSewerPageWithQuery = (description) => {
      window.location.href = `${window.SITE_URL}keurders?search=${encodeURIComponent(description)}`;
    };

    const $searchInput = $('.ui.search.sewer-search input');
    $('.ui.search.sewer-search')
      .search({
        templates: {
          standard: (response) => { // eslint-disable-line
            let htmlResponse = '';
            response.results.forEach((result) => {
              htmlResponse = `${htmlResponse}
                <a class="result" onclick="window.goToSewerPageWithQuery('${result.description}');">
                  <div class="content">
                    <div class="description">${result.description}</div>
                  </div>
                </a>
              `;
            });
            return htmlResponse;
          },
        },
        apiSettings: {
          onResponse: (apiResponse) => {
            const response = {
              results: apiResponse.results,
            };
            return response;
          },
          responseAsync: (settings, callback) => {
            const request = { input: $searchInput.val() };
            this.autoCompleteService.getQueryPredictions(request, (results, status) => {
              let response = {
                success: false,
              };
              if (status === google.maps.places.PlacesServiceStatus.OK) { // eslint-disable-line
                response = {
                  success: true,
                  results,
                };
              }
              callback(response);
            });
          },
        },
      });
  }

  onEducationInspectionsDataReceived(data) {
    this.inspectionEucationLoaded = true;
    this.inspectionEducation = data.data;
    // this.initCalendar();
  }

  onEventsDataReceived(data) {
    this.eventsLoaded = true;
    this.events = data.data;
    // this.initCalendar();
  }

  initCalendar() {
    if (!this.eventsLoaded || !this.inspectionEucationLoaded) {
      return;
    }

    const dates = [];
    const dateEventMap = new Map();
    const calendarInfo = document.querySelector('.js-calendar-info');
    const calendarInfoLink = calendarInfo.querySelector('.calendar-info__link');
    const calendarInfoTitle = calendarInfo.querySelector('.calendar-info__title');
    const calendarInfoDate = calendarInfo.querySelector('.calendar-info__date');
    const data = this.events
      .concat(this.inspectionEducation)
      .sort((calendarItem1, calendarItem2) => calendarItem2.date.date - calendarItem1.date.date);

    data.forEach((obj) => {
      if (obj.date) {
        const time = new Date(obj.date.date).getTime();
        dates.push(time);
        dateEventMap.set(time, obj);
      }
    });

    const datepicker = $('[data-toggle="datepicker"]').datepicker({
      inline: true,
      container: '.js-calendar-datepicker',
      autoPick: true,
      filter: (date) => {
        if ($.inArray(date.getTime(), dates) > -1) {
          return true;
        }
        return false;
      },
    });

    datepicker.on('pick.datepicker', (e) => {
      calendarInfo.classList.remove('hide');
      const eventObj = dateEventMap.get(e.date.getTime());
      calendarInfoLink.setAttribute('href', eventObj.url);
      calendarInfoDate.innerHTML = new Date(e.date).toLocaleDateString('nl-BE');
      calendarInfoTitle.innerHTML = eventObj.title;
    });
  }
}
