import $ from 'jquery';

export default class FAQBlock {
  constructor(domEl) {
    this.element = domEl;
    this.toggle = domEl.querySelector('.faq-block__toggle');
    this.content = domEl.querySelector('.faq-block__answer');
    this.initEventListeners();
  }

  initEventListeners() {
    this.toggle.addEventListener('click', () => {
      $(this.content).toggle('fast');
      this.toggle.classList.toggle('js-active');
    });
  }
}
