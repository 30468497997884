import $ from 'jquery';
import 'jquery-ui/ui/effect';
import '../plugins/semantic-search';

export default class CompanySearch {
  constructor() {
    this.init();
    this.companiesJSON = null;
  }

  init() {
    $.getJSON(`${window.SITE_URL}cities.json`, this.onDataReceived);
  }

  onDataReceived(data) {
    this.companiesJSON = data.data;
    const content = [];
    this.companiesJSON.forEach((company) => {
      content.push({ title: company.title, description: company.zip, url: company.url });
    });

    $('.ui.search')
      .search({
        source: content,
        error: {
          source: 'Cannot search. No source used, and Semantic API module was not included',
          noResults: 'Er werden geen resultaten gevonden',
          logging: 'Error in debug logging, exiting.',
          noTemplate: 'A valid template name was not specified.',
          serverError: 'There was an issue with querying the server.',
          maxResults: 'Results must be an array to use maxResults setting',
          method: 'The method you called is not defined.',
        },
      });
  }
}
