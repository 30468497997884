import Helpers from '../utils/Helpers';

/*
 * searchInput: the text input the user uses to search
 * elementGroups: [{ elements:[], noResultsEl: Node, noResultsText: String }]
 */
export default class PageSearch {
  constructor(searchInput, elementGroups) {
    this.searchInput = searchInput;
    this.elementGroups = elementGroups;
    this.init();
  }

  init() {
    this.searchInput.addEventListener('input', () => {
      const inputText = Helpers.normalizeText(this.searchInput.value);
      this.elementGroups.forEach((group) => {
        this.filterGroup(group, inputText);
      });
    });
  }

  filterGroup(group, inputText) {
    const { elements, noResultsEl } = group;
    let { noResultsText } = group;
    noResultsText = Helpers.normalizeText(noResultsText);

    let groupContainsElement = false;
    elements.forEach((el) => {
      if (el.dataset.search.indexOf(inputText) >= 0) {
        groupContainsElement = true;
        el.classList.remove('hide');
      } else {
        el.classList.add('hide');
      }
    });

    if (!groupContainsElement) {
      noResultsEl.innerHTML = noResultsText;
      noResultsEl.classList.remove('hide');
    } else {
      noResultsEl.innerHTML = '';
      noResultsEl.classList.add('hide');
    }
  }
}
