export default class Helpers {
  static normalizeText(text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  static getQueryParams(qs) {
    const qsSplitted = qs.replace(/\+/g, ' ');

    const params = {};
    let tokens;
    const re = /[?&]?([^=]+)=([^&]*)/g;
    tokens = re.exec(qsSplitted);
    while (tokens) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
      tokens = re.exec(qsSplitted);
    }

    return params;
  }
}
