/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
export default function () {
  const videos = document.querySelectorAll('.player');

  const getIframeVideoFromUrl = (url) => {
    const urlString = url;
    let videoId = '';
    if (urlString.includes('?t=')) {
      const urlStringWithoutTime = urlString.split('?t=')[0];
      videoId = urlStringWithoutTime.split('watch?v=')[1];
    } else {
      videoId = urlString.split('watch?v=')[1];
    }

    return `<iframe
      class=""
      src="https://www.youtube.com/embed/${videoId}"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer;
      autoplay;
      clipboard-write;
      encrypted-media;
      gyroscope;
      picture-in-picture"
      allowfullscreen>
    </iframe>`;
  };

  const playerSetup = () => {
    videos.forEach((el) => {
      el.innerHTML = getIframeVideoFromUrl(el.dataset.embedSrc);
    });
  };

  const init = () => {
    if (videos) {
      playerSetup();
    }
  };

  init();
}
