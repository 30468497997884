export default function () {
  [...document.querySelectorAll('.dropzone-input')].forEach((dropzone) => {
    const input = document.getElementById(dropzone.getAttribute('data-input-id'));
    input.addEventListener('change', () => {
      dropzone.classList.add('dropzone-input--completed');
    });
    dropzone.addEventListener('click', () => {
      document.getElementById(dropzone.getAttribute('data-input-id')).click();
    });
  });
}
