import $ from 'jquery';

export default function () {
  const $trigger = $('.enlarge--block');

  function triggerCheck() {
    $('.enlarge--block').find('.js-enlarge__trigger').text('+');
    $('.enlarge--block.open').find('.js-enlarge__trigger').text('-');
  }
  triggerCheck();

  $trigger.on('click', function trigger() {
    $(this).closest('.enlarge--block').find('.enlarge-body').slideToggle('fast');
    $(this).closest('.enlarge--block').toggleClass('open');
    triggerCheck();
  });
}
