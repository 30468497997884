export default class VisibilityTrigger {
  constructor(domEl) {
    this.element = domEl;
    this.hideQuery = domEl.getAttribute('data-hide-query');
    this.showQuery = domEl.getAttribute('data-show-query');
    this.init();
  }

  init() {
    this.initEventListeners();
  }

  initEventListeners() {
    if (this.element.getAttribute('type') === 'checkbox') {
      this.element.addEventListener('change', () => {
        const elementsToShow = [...document.querySelectorAll(this.showQuery)];
        if (this.element.checked) {
          elementsToShow.forEach((el) => {
            el.classList.remove('hide');
          });
        } else {
          elementsToShow.forEach((el) => {
            el.classList.add('hide');
          });
        }
      });
    } else {
      this.element.addEventListener('click', () => {
        if (this.hideQuery) {
          const elementsToHide = [...document.querySelectorAll(this.hideQuery)];
          elementsToHide.forEach((el) => {
            el.classList.add('hide');
          });
        }
        if (this.showQuery) {
          const elementsToShow = [...document.querySelectorAll(this.showQuery)];
          elementsToShow.forEach((el) => {
            el.classList.remove('hide');
          });
        }
      });
    }
  }
}
