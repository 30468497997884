export default class InspectionEducationIndex {
  constructor() {
    this.elementsToFilter = document.querySelectorAll('.grid__item[data-category]');
    this.categorySelect = document.getElementById('category-select');
    this.init();
  }

  init() {
    this.categorySelect.addEventListener('change', () => {
      const selectedValue = this.categorySelect.value;
      this.elementsToFilter.forEach((el) => {
        el.classList.remove('hide');
      });
      if (selectedValue !== 'show-all') {
        const notMatched = document.querySelectorAll(`.grid__item[data-category]:not([data-category="${selectedValue}"])`);
        notMatched.forEach((el) => {
          el.classList.add('hide');
        });
      }
    });
  }
}
